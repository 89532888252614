import React from "react";
import InstagramEmbed from "react-instagram-embed";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";

import gem1 from "assets/img/gem1.JPEG";
import gem2 from "assets/img/gem2.JPEG";
import gem3 from "assets/img/gem3.JPG";
import gem4 from "assets/img/gem4.JPEG";
import gem5 from "assets/img/gem5.JPEG";
import gem6 from "assets/img/gem6.JPG";
import gem7 from "assets/img/gem7.JPEG";
import gem8 from "assets/img/gem3.JPG";
import gem9 from "assets/img/gem9.JPEG";
import gem10 from "assets/img/gem10.JPEG";
import gem11 from "assets/img/gem11.JPG";
import gem12 from "assets/img/gem12.JPEG";
import gem13 from "assets/img/gem13.JPG";
import gem14 from "assets/img/gem14.JPG";
import gem15 from "assets/img/gem15.PNG";
import gem16 from "assets/img/gem16.PNG";
import gem17 from "assets/img/gem17.PNG";
const productData = [
  {
    name: "Tourmaline",
    img: gem1,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Tourmaline",
    img: gem2,
    sentence:
      "Bio Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Petroleum Quartz",
    img: gem3,
    sentence:
      "Petroleum quartz is a very transparent, “water clear” crystal. The faces are smooth and very lustrous without pronounced striations, making the perfect canvas for the inclusions with no interference inside or outside the crystal. The most visible inclusion is the petroleum trapped in the cavities and negative crystals.",
  },

  {
    name: "Blue Beyrl",
    img: gem5,
    sentence: "One of a kind Blue Beyrl.",
  },

  {
    name: "Blue Beyrl",
    img: gem7,
    sentence: "One of kind Blue Beyrl",
  },
  {
    name: "Petroleum Quartz",
    img: gem8,
    sentence:
      "Petroleum quartz is a very transparent, “water clear” crystal. The faces are smooth and very lustrous without pronounced striations, making the perfect canvas for the inclusions with no interference inside or outside the crystal. The most visible inclusion is the petroleum trapped in the cavities and negative crystals.",
  },
  {
    name: "Tourmaline",
    img: gem9,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Tourmaline",
    img: gem10,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Tourmaline",
    img: gem11,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Green Tourmaline",
    img: gem12,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Aquamarine",
    img: gem13,
    sentence:
      "Aquamarine is the green-blue to blue variety of the mineral beryl. ... (Emerald is the green to bluish green variety of the same mineral.) Its color is usually a light pastel greenish blue.",
  },
  {
    name: "Tourmaline",
    img: gem14,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Dark Blue Beyrl",
    img: gem15,
    sentence:
      "Beryl is a mineral composed of beryllium aluminium cyclosilicate with the chemical formula Be₃Al₂Si₆O₁₈. Well-known varieties of beryl include emerald and aquamarine. Naturally occurring, hexagonal crystals of beryl can be up to several meters in size, but terminated crystals are relatively rare",
  },
  {
    name: "Tourmaline",
    img: gem16,
    sentence:
      "Tourmaline is a gemstone that is akin to granite in structure and is acknowledged for its magical powers. This gemstone has a unique quality of creating an amount of electrical charge and can give out negative charge.",
  },
  {
    name: "Minerals",
    img: gem17,
  },
];
const useStyles = makeStyles(styles);

export default function SectionLatestOffers() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2>Inventory</h2>
        <GridContainer>
          {productData &&
            productData.map((p, index) => (
              <GridItem md={4} sm={4}>
                <Card product plain>
                  <CardHeader image plain>
                    <img src={p.img} alt="..." className={classes.images} />
                    <div
                      className={classes.coloredShadow}
                      style={{ backgroundImage: `url(${p.img})`, opacity: 1 }}
                    />
                  </CardHeader>
                  <CardBody className={classes.textCenter} plain>
                    <h4 className={classes.cardTitle}>{p.name}</h4>
                    <p className={classes.cardDescription}>{p.sentence}</p>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </div>
    </div>
  );
}
