/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "./Header";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionLatestOffers from "views/EcommercePage/Sections/SectionLatestOffers.js";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.js";
import SectionBlog from "views/EcommercePage/Sections/SectionBlog.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
import Mail from "@material-ui/icons/Mail";

import ecommerceHeader from "assets/img/examples/ecommerce-header.jpg";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import SectionFeatures from "views/SectionsPage/Sections/SectionFeatures";

const useStyles = makeStyles(styles);

export default function EcommercePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionLatestOffers />
      </div>

      <Footer theme="dark">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h5>About Us</h5>
            <p>
              Your trust in us, and the beautiful inventory we offer, is honored
              by a full-satisfaction guarantee. Every lovely stone item has been
              inspected, researched, described and pictured for accuracy and
              full disclosure. All information clearly supplied, and all
              questions/inquiries promptly addressed.
            </p>
            <p> </p>
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
}
